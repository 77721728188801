import React, { Component } from "react";
import Cloud from "./components/Cloud";



class Home extends Component {
  
    constructor(props) {
        super(props);
        this.texts = []
        this.texts[0] = 'Cloud: all U need is Cloud';
        this.texts[1] = 'Cloud: do not fall for scam';
        this.texts[2] = 'Cloud: others will make u rain';
        this.texts[3] = 'Cloud: we are the sun behind dark clouds';
        this.texts[4] = 'Is there life beyond the Cloud? -> Never!';
        this.text = this.texts[0];
        this.state = { number: 1 };
        this.makeTimer();
    }

    makeTimer() {
        setInterval(() => {
            let rand = Math.floor(Math.random() * 40) + 1;
            this.setState({ number: rand });
            this.text = this.texts[Math.floor(Math.random()*this.texts.length)];
            
        }, 12500);
    }
    render() {
        document.title = this.text
        return (
          <div>
            <Cloud range={this.state.number} />
            <h1>{this.text}</h1>
            <a target='_blank'
            rel='noopener noreferrer' href="https://cloud.4-it.de/s/kDyDqPSLAikeKPW">Halloween2024 - Bilder</a>
          </div>
        );
    }
}

export default Home;
